export const environment = {
  production: false,
  // urlApi: 'http://localhost:8000/api/v1',
  urlApi: 'https://akznu8qny4.execute-api.us-east-1.amazonaws.com/dev/api/v1',
  idCompany: '100612770306719751',
  firebase: {
    apiKey: 'AIzaSyCtvETPosxAgt9mugIwsktL2sI21mfLjE0',
    authDomain: 'contador-cleversec.firebaseapp.com',
    databaseURL: 'https://contador-cleversec-default-rtdb.firebaseio.com',
    projectId: 'contador-cleversec',
    storageBucket: 'contador-cleversec.appspot.com',
    messagingSenderId: '591702842509',
    appId: '1:591702842509:web:983a8d172f33e7699a96c4',
  },
};
